<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'purchases-items-create' })"
        >
          <i class="bi bi-file-earmark-plus"></i>
          Rajoutez un nouvel article
        </button>
      </div>
    </div>
    <br />
    <div class="row px-2">
      <div :class="errors != [] ? 'rounded bg-white border' : ''">
        <div v-for="(error, index) in errors" :key="index++" class="col-12">
          <div class="text-danger py-1">
            {{ index + " - " + error }}
          </div>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <div class="row justify-content-between">
      <div class="col d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row align-items-end">
      <div class="col">
        <label for="">Fournisseur <span class="text-danger">*</span> </label>
        <select v-model="purchase.supplier_reference" class="form-select">
          <option value=""></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society + " " + supplier.fullName }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="$router.push({ name: 'suppliers-create' })"
          class="btn btn-secondary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un fournisseur
        </button>
      </div>

      <div class="col-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date" v-model="purchase.date" class="form-control" />
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-3">
        <label for="">
          Bon de Commande Nº <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          v-model="purchase.orderNumber"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Bon Livraison Nº </label>
        <input
          type="text"
          v-model="purchase.deliveryOrderNumber"
          class="form-control"
        />
      </div>
      <div class="col-3">
        <label for="">Facture Nº <span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="purchase.invoiceNumber"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Montant Payé <span class="text-danger">*</span> </label>
        <input type="text" v-model="purchase.amountPaid" class="form-control" />
      </div>

      <div class="col-3" v-if="purchase.amountPaid > 0">
        <label for=""
          >Mode de paiement <span class="text-danger">*</span></label
        >
        <select v-model="purchase.paymentMethod" class="form-select">
          <option value="Chèque">Chèque</option>
          <option value="Espèces">Espèces</option>
          <option value="Virement bancaire">Virement bancaire</option>
        </select>
      </div>

      <div class="col-3" v-if="purchase.paymentMethod == 'Chèque'">
        <label for="">Chèque Nº <span class="text-danger">*</span> </label>
        <input
          type="text"
          v-model="purchase.checkNumber"
          class="form-control"
        />
      </div>

      <div class="col-3" v-if="purchase.paymentMethod == 'Virement bancaire'">
        <label for="">Virement Nº <span class="text-danger">*</span> </label>
        <input
          type="text"
          v-model="purchase.transferNumber"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat (Fournisseur,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="save(purchase)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      purchase: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        amountPaid: 0.0,
      },
      accept: false,
    };
  },
  computed: {
    ...mapGetters("purchase", {
      purchase_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("purchase/store", data);

      await setTimeout(() => {
        this.purchase = {
          date: new Date(new Date().setFullYear(new Date().getFullYear()))
            .toLocaleString("en-CA")
            .split(",")[0],
          amountPaid: 0.0,
        };
        this.accept = false;
      }, 2000);
    },
    async refresh() {
      this.$store.dispatch("purchase/getAllItems");
    },
  },
  async beforeMount() {
    await this.$store.dispatch("purchase/getAllItems");
    await this.$store.dispatch("supplier/getAll");
    (await !this.numberItems)
      ? (await this.$store.dispatch("notification/store", {
          status: "error",
          message: "Aucun élément n'est ajouté. Veuillez en ajouter.",
        }),
        await this.$router.push({ name: "purchases-items-create" }))
      : "";
  },
};
</script>
